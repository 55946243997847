.Footer {
   background-color: #FFD361;
   min-height: 400px;
   margin-top: 60px;
}

.Footer .container {
   display: flex;
   justify-content: space-between;
   padding-top: 30px;
   position: relative;
}

.Footer .container>a {
   position: absolute;
   bottom: 0;
   left: 0;
   color: #000000;
   font-size: 12px;
}

.Footer .container .content {
   width: 48%;
}

.Footer .container .content .logo {
   font-style: normal;
   font-weight: bold;
   font-size: 42px;
   line-height: 50px;
   text-align: center;
   letter-spacing: 0.2em;
   color: #2C2E33;
}

.Footer .container .content h1 {
   font-style: normal;
   font-weight: bold;
   font-size: 26px;
   line-height: 31px;
   margin-top: 60px;
   color: #2C2E33;
}

.Footer .container .content p {
   font-style: normal;
   font-weight: normal;
   font-size: 26px;
   line-height: 31px;
   color: #000000;
   margin-top: 15px;
   margin-bottom: 60px;
}

.Footer .container iframe {
   margin-top: -150px;
   width: 511px;
   height: 511px;
   box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.25);
   border-radius: 50%;
   border: none;
}

@media only screen and (max-width: 1000px) {
   .Footer {
      height: 90vh;
   }

   .Footer .container .content {
      width: 100%;
   }

   .Footer .container {
      flex-direction: column;
   }

   .Footer .container iframe {
      margin-top: 0;
   }
}

@media only screen and (max-width: 600px) {
   .Footer .container iframe {
      border-radius: 10px;
   }

   .Footer .container .content .logo {
      font-size: 32px;
      line-height: 50px;
      text-align: center;
      letter-spacing: 0.2em;
      color: #2C2E33;
   }
}