.Building{
   padding-bottom: 60px;
}
.Building .container .content{
   padding-top: 90px;
}
.Building .container .content .Secondary_Text{
   width: 70%;
   margin-top: 15px;
}
.Building .container .carusel{
   margin-top: 50px;
   overflow: hidden;
   text-align:center;
}
.Building .container .carusel .slider{
   height:369px;
   display: flex;
   align-items: center;
   transition:1s;
   text-align: start;
}
.C_Card{
   min-width: 220px;
   height: 186px;
   background-color: white;
   clip-path: path("M48.246 10.5969C50.1799 4.29893 55.997 0 62.5853 0H194.922C204.946 0 212.149 9.64163 209.307 19.2534L163.218 175.117C161.314 181.555 155.365 185.944 148.652 185.862L15.0405 184.243C5.02864 184.122 -2.05599 174.412 0.883079 164.841L48.246 10.5969Z");
   /*transition:.1s*/
   position: relative;
}
.active{
   min-width: 695.5px;
   height: 369px;
   background-color: white;
   clip-path: path("M0 35C0 15.67 15.67 0 35 0H646.753C670.696 0 687.57 23.5024 679.916 46.1892L579.034 345.189C574.233 359.419 560.888 369 545.87 369H35C15.67 369 0 353.33 0 334V35Z");
   transition:All .2s;
   position:relative;
}

.Building .container .carusel .slider .active button{
   margin-top: 40px;
}
.img{
   min-width: 100%;
   height:100%;
   position: absolute;
   top: 0;
   left: 0;
   object-fit: cover;
}
.Building .container .carusel>.btn_prime{
   margin-top: 90px;

}
@media only screen and (max-width: 950px){
   .Building .container .carusel .slider{
      min-height: 150px;
   }
   .Building .container .carusel .btn_prime,.Building .container .carusel{
      margin-top: 0;
   }
   .C_Card{
      clip-path: path("M18.7212 7.07729C20.0063 2.87222 23.8875 0 28.2845 0H76.6329C83.3097 0 88.1111 6.41815 86.2261 12.8233L68.8891 71.7318C67.6241 76.0301 63.6544 78.9623 59.1741 78.9077L10.3411 78.313C3.6723 78.2318 -1.04973 71.7691 0.899513 65.391L18.7212 7.07729Z");
      min-width: 93px;
      height: 79px;
      border-radius: 10px;
   }
   .active{
      min-width: 284.18px;
      clip-path: path("M0 15C0 6.7157 6.71573 0 15 0H263.291C273.552 0 280.784 10.0725 277.504 19.7954L236.755 140.57C234.697 146.668 228.978 150.774 222.542 150.774H15C6.71572 150.774 0 144.058 0 135.774V15Z");
      height: 150.77px;
      padding: 12px;
   }
}