@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Black.eot');
    src: local('Harmonia Sans Pro Cyr Black'), local('HarmoniaSansProCyr-Black'),
        url('HarmoniaSansProCyr-Black.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-Black.woff2') format('woff2'),
        url('HarmoniaSansProCyr-Black.woff') format('woff'),
        url('HarmoniaSansProCyr-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-BoldIt.eot');
    src: local('Harmonia Sans Pro Cyr Bold Italic'), local('HarmoniaSansProCyr-BoldIt'),
        url('HarmoniaSansProCyr-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-BoldIt.woff2') format('woff2'),
        url('HarmoniaSansProCyr-BoldIt.woff') format('woff'),
        url('HarmoniaSansProCyr-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Semi Bd';
    src: url('HarmoniaSansProCyr-SemiBdIt.eot');
    src: local('Harmonia Sans Pro Cyr Semi Bold Italic'), local('HarmoniaSansProCyr-SemiBdIt'),
        url('HarmoniaSansProCyr-SemiBdIt.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-SemiBdIt.woff2') format('woff2'),
        url('HarmoniaSansProCyr-SemiBdIt.woff') format('woff'),
        url('HarmoniaSansProCyr-SemiBdIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Light.eot');
    src: local('Harmonia Sans Pro Cyr Light'), local('HarmoniaSansProCyr-Light'),
        url('HarmoniaSansProCyr-Light.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-Light.woff2') format('woff2'),
        url('HarmoniaSansProCyr-Light.woff') format('woff'),
        url('HarmoniaSansProCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-BlackIt.eot');
    src: local('Harmonia Sans Pro Cyr Black Italic'), local('HarmoniaSansProCyr-BlackIt'),
        url('HarmoniaSansProCyr-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-BlackIt.woff2') format('woff2'),
        url('HarmoniaSansProCyr-BlackIt.woff') format('woff'),
        url('HarmoniaSansProCyr-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Bold.eot');
    src: local('Harmonia Sans Pro Cyr Bold'), local('HarmoniaSansProCyr-Bold'),
        url('HarmoniaSansProCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-Bold.woff2') format('woff2'),
        url('HarmoniaSansProCyr-Bold.woff') format('woff'),
        url('HarmoniaSansProCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-LightIt.eot');
    src: local('Harmonia Sans Pro Cyr Light Italic'), local('HarmoniaSansProCyr-LightIt'),
        url('HarmoniaSansProCyr-LightIt.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-LightIt.woff2') format('woff2'),
        url('HarmoniaSansProCyr-LightIt.woff') format('woff'),
        url('HarmoniaSansProCyr-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Cond';
    src: url('HarmoniaSansProCyr-BoldCond.eot');
    src: local('Harmonia Sans Pro Cyr Bold Cond'), local('HarmoniaSansProCyr-BoldCond'),
        url('HarmoniaSansProCyr-BoldCond.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-BoldCond.woff2') format('woff2'),
        url('HarmoniaSansProCyr-BoldCond.woff') format('woff'),
        url('HarmoniaSansProCyr-BoldCond.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Italic.eot');
    src: local('Harmonia Sans Pro Cyr Italic'), local('HarmoniaSansProCyr-Italic'),
        url('HarmoniaSansProCyr-Italic.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-Italic.woff2') format('woff2'),
        url('HarmoniaSansProCyr-Italic.woff') format('woff'),
        url('HarmoniaSansProCyr-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Semi Bd';
    src: url('HarmoniaSansProCyr-SemiBd.eot');
    src: local('Harmonia Sans Pro Cyr Semi Bd'), local('HarmoniaSansProCyr-SemiBd'),
        url('HarmoniaSansProCyr-SemiBd.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-SemiBd.woff2') format('woff2'),
        url('HarmoniaSansProCyr-SemiBd.woff') format('woff'),
        url('HarmoniaSansProCyr-SemiBd.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Regular.eot');
    src: local('Harmonia Sans Pro Cyr'), local('HarmoniaSansProCyr-Regular'),
        url('HarmoniaSansProCyr-Regular.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-Regular.woff2') format('woff2'),
        url('HarmoniaSansProCyr-Regular.woff') format('woff'),
        url('HarmoniaSansProCyr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr SemiBdCn';
    src: url('HarmoniaSansProCyr-SemiBdCn.eot');
    src: local('Harmonia Sans Pro Cyr Semi Bold Cond'), local('HarmoniaSansProCyr-SemiBdCn'),
        url('HarmoniaSansProCyr-SemiBdCn.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-SemiBdCn.woff2') format('woff2'),
        url('HarmoniaSansProCyr-SemiBdCn.woff') format('woff'),
        url('HarmoniaSansProCyr-SemiBdCn.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Cond';
    src: url('HarmoniaSansProCyr-Cond.eot');
    src: local('Harmonia Sans Pro Cyr Cond'), local('HarmoniaSansProCyr-Cond'),
        url('HarmoniaSansProCyr-Cond.eot?#iefix') format('embedded-opentype'),
        url('HarmoniaSansProCyr-Cond.woff2') format('woff2'),
        url('HarmoniaSansProCyr-Cond.woff') format('woff'),
        url('HarmoniaSansProCyr-Cond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

